import React from "react";
import { useState, useEffect } from 'react';
import { createStore } from "redux";
import { Provider } from "react-redux";
// import { composeWithDevTools } from "redux-devtools-extension";
import Apdash from "./reducers";
import Routes from "./routers";


import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { messages as messagesNL } from './locales/nl/messages'
import { messages as messagesEN } from './locales/en/messages'
import { messages as messagesFR } from './locales/fr/messages'


// create store
const store = createStore(
  Apdash,
  // composeWithDevTools()
  // applyMiddleware(...middleware),
  // other store enhancers if any
);
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // 
    }
  };
  return [storedValue, setValue];
}



i18n.load('en', messagesEN)
i18n.load('fr', messagesFR)
i18n.load('nl', messagesNL)
// i18n.activate('en')

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const landingLanguage = urlParams.get("l")

  const [lang, setLang] = useLocalStorage("lang", "en")

  if (landingLanguage === 'en' || landingLanguage === 'fr' || landingLanguage === 'nl' ) {
    if (landingLanguage !== lang) {
      setLang(landingLanguage)
    }
  }

  console.log(lang)
  i18n.activate(lang)


  const setLanguage = code => {
    setLang(code)
    i18n.activate(code)
  }

  return (
    <I18nProvider i18n={i18n}>
      <Provider store={store}>
        <Routes setLang={setLanguage} lang={lang}/>
      </Provider>
    </I18nProvider>
  );
}

export default App;
