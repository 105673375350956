import React from "react";
import { connect } from "react-redux";
import _data from "../../data";
import { Trans, t } from '@lingui/macro'

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      promo: _data.promo
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                            <div className="card-body">
                                <div className="pb-2">
                                    <span className="fas fa-lock icon-size-md color-secondary"></span>
                                </div>
                                <div className="pt-2 pb-3">
                                    <h5>{t`Secure data`}</h5>
                                    <p className="mb-0">{t`Store your client records safely and access them from anywhere.`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                            <div className="card-body">
                                <div className="pb-2">
                                    <span className="fas fa-tools icon-size-md color-secondary"></span>
                                </div>
                                <div className="pt-2 pb-3">
                                    <h5>{t`Maintenance reminders`}</h5>
                                    <p className="mb-0">{t`Visit your client on time everytime`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                            <div className="card-body">
                                <div className="pb-2">
                                    <span className="fas fa-sync-alt icon-size-md color-secondary"></span>
                                </div>
                                <div className="pt-2 pb-3">
                                    <h5>{t`Auto Schedule meetings`}</h5>
                                    <p className="mb-0">{t`Automatically SMS your clients`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4 shadow">
                            <div className="card-body">
                                <div className="pb-2">
                                    <span className="fas fa-tasks icon-size-md color-secondary"></span>
                                </div>
                                <div className="pt-2 pb-3">
                                    <h5>{t`To do's`}</h5>
                                    <p className="mb-0">{t`Sticky notes you can use anytime.`}</p>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
