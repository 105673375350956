import React, { Component } from "react";
import { connect } from "react-redux";
import { submitContact } from "../../actions/index";
import _data from "../../data";
import { useState } from "react";
import { Trans, t } from '@lingui/macro'



// class Contact extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: "",
//       email: "",
//       phone: "",
//       message: "",
//       disableContactBtn: false,
//       contactBtnText: "Send Message",
//       contact: {}
//     };

//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.message = this.message.bind(this);
//   }

//   /**
//    * When we click on Send button, changeBtnText function will help to change text
//    * @param contactBtnText
//    */
//   changeBtnText = contactBtnText => {
//     this.setState({ contactBtnText });
//   };

//   /**
//    * Get all form data and set to the state
//    * @param contactBtnText
//    */
//   handleFormValueChange(inputName, event) {
//     let stateValue = {};
//     stateValue[inputName] =
//       event.target.type === "checkbox"
//         ? event.target.checked
//         : event.target.value;
//     this.setState(stateValue);
//   }

//   /**
//    * Submit the form and dispatch to the store
//    * @param contactBtnText
//    */
//   handleSubmit(event) {
//     event.preventDefault();

//     let error = false;
//     if(!(this.state.name && this.state.email && this.state.message)) {
//       error = true;
//     }

//     if(!error) {
//       // disable the button
//       this.setState({ disableContactBtn: true });

//       // get action
//       const contactAction = submitContact(this.state);

//       // Dispatch the contact from data
//       this.props.dispatch(contactAction);

//       // added delay to change button text to previous
//       setTimeout(
//         function() {
//           // enable the button
//           this.setState({ disableContactBtn: false });

//           // change to button name
//           this.changeBtnText("Send Message");

//           // get action again to update state
//           const contactAction = submitContact(this.state);

//           // Dispatch the contact from data
//           this.props.dispatch(contactAction);

//           // clear form data
//           this.setState({
//             name: "",
//             email: "",
//             message: ""
//           });

//           // Set success message
//           this.message(error);
//         }.bind(this),
//         3000
//       );
//     } else {
//       // Set error message
//       this.message(error);

//       // enable the button
//       this.setState({ disableContactBtn: false });

//       // change to button name
//       this.changeBtnText("Send Message");
//     }

//   }

//   message(error) {
//     const messageBox = document.querySelector('#contact .message-box');
//     if (messageBox.classList.contains("d-none")) {
//       messageBox.classList.remove("d-none");
//     }
//     messageBox.classList.add("d-block");
//     if(error) {
//       if (messageBox.classList.contains("alert-success")) {
//         messageBox.classList.remove("alert-success");
//       }
//       messageBox.classList.add("alert-danger");
//       messageBox.innerHTML = 'Found error in the form. Please check again.';
//     }
//     else {
//       if (messageBox.classList.contains("alert-danger")) {
//         messageBox.classList.remove("alert-danger");
//       }
//       messageBox.classList.add("alert-success");
//       messageBox.innerHTML = 'Form submitted successfully';
//     }
//   }

//   componentDidMount() {
//     /**
//      * Your ajax will goes here to get data then call setState
//      */
//     this.setState({
//       contact: _data.contact
//     });
//   }

//   render() {

	function postAjax(url, data, success) {
    var params = typeof data == 'string' ? data : Object.keys(data).map(
            function(k){ return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
        ).join('&');

    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new window.ActiveXObject("Microsoft.XMLHTTP");
    xhr.open('POST', url);
    xhr.onreadystatechange = function() {
        if (xhr.readyState>3 && xhr.status==200) { success(xhr.responseText); }
    };
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(params);
    return xhr;
}


const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [formPosted, setFormPosted] = useState(false)
  const [disableContactBtn, setDisableContactBtn] = useState(false)
  const [contactBtnText, setContactBtnText] = useState("Send Message")

  const handleChange = (name, val) => {
    setForm({ ...form, [name]: val })
  }

  const isValid = () => {
    const message = form.message.trim()
    // const email = form.email.trim()
    // const name = form.name.trim()

    // return valid is there's a "message", let's consider
    // the name and email as optional:
    return (message && message.length >= 4);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid()) {
      // disable the button
      // setDisableContactBtn(true)
      postAjax(_data.contactFormEndpoint, form, (data) => {
        // console.log(data);
        setFormPosted(true);
      });
    }
  }
    return (
      <React.Fragment>
        <section id="contact" className="contact-us-section ptb-100">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-12 pb-3 mb-4 message-box d-none alert alert-success"></div>
              <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                <div className="contact-us-form gray-light-bg rounded p-5">

                  {formPosted ? (
                    <>
                      <h4>{t`Thanks for your message`}</h4>
                    </>
                  ) : (
                    <>
                      <h4>{t`Ready to get started?`}</h4>
                      <form action="" id="contactForm" className="contact-us-form" onSubmit={(event) => handleSubmit(event)}>
                        <div className="form-row">
                          <div className="col-12">
                            <div className="form-group">
                              <input type="text" className="form-control" name="name" value={form.name} onChange={e => handleChange("name", e.target.value)} placeholder={t`Enter name`} />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <input type="email" className="form-control" name="email" value={form.email} onChange={e => handleChange("email", e.target.value)} placeholder={t`Enter email`} />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <textarea name="message" value={form.message} id="message" className="form-control" rows="7" cols="25" placeholder="Message" onChange={e => handleChange("message", e.target.value)}></textarea>
                            </div>
                          </div>
                          <div className="col-sm-12 mt-3">
                            <button type="submit" className="btn btn-brand-01" id="btnContactUs" disabled={!isValid()} onClick={() => { this.changeBtnText("Sending..."); }}>
                              {contactBtnText}
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}

                  
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="contact-us-content">
                  <h2>{t`Don't hesitate to contact us`}</h2>
                  <p className="lead">{t`Give us a call or email us anytime, we endeavour to answer all enquiries within 24 hours on business days.`}</p>

                  {/* <a href="/#" className="btn btn-outline-brand-01 align-items-center">Get Directions <span className="ti-arrow-right pl-2"></span></a> */}

                  <hr className="my-5" />

                  <ul className="contact-info-list">
                    {/* <li className="d-flex pb-3">
                                    <div className="contact-icon mr-3">
                                        <span className={(this.state.contact.addressIcon) + " color-primary rounded-circle p-3"}></span>
                                    </div>
                                    <div className="contact-text">
                                        <h5 className="mb-1">{this.state.contact.addressTitle}</h5>
                                        <p>
                                        {this.state.contact.address}
                                        </p>
                                    </div>
                                </li> */}
                    {/**
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className={"fas fa-phone color-primary rounded-circle p-3"}></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">{t`Phone`}</h5>
                        <p>
                          +32 472 57 27 13
                        </p>
                      </div>
                    </li>
                    */}
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className={"fas fa-envelope color-primary rounded-circle p-3"}></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">{t`Email Address`}</h5>
                        <p>
                          info@consolarium.com
                        </p>
                      </div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }


  export default Contact;
