import React from "react";
import { Trans, t } from '@lingui/macro'

class TeamMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teamMember: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section id="team-members" className={"team-two-section ptb-100 " + (this.props.isWhite && this.props.isWhite === true ? '' : 'gray-light-bg')}>
                    <div className="container">
                        {!(this.props.hideTitle && this.props.hideTitle === true) && (
                            <div className="row justify-content-center">
                                <div className="col-md-9 col-lg-8">
                                    <div className="section-heading text-center">
                                        <h2>{t`Our Team Members`}</h2>
                                        {/* <p>Authoritatively mesh intuitive paradigms vis-a-vis goal-oriented partnerships. Continually extend open-source outside the box thinking after focused catalysts.</p> */}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                                    <img src="assets/img/shean.jpeg" alt="team" width="120" className="img-fluid m-auto pb-4" />
                                    <div className="team-content">
                                        <h5 className="mb-0">Shean Massey</h5>
                                        <span>{t`Lead developer`}</span>
                                        {/* <p className="mt-3">Authoritatively engage leading-edge processes tactical capital </p> */}
                                        <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                            {/* <li className="list-inline-item">
                                        <a className="facebook" href="/#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="twitter" href="/#" target="_blank"><i className="fab fa-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="dribbble" href="/#" target="_blank"><i className="fab fa-dribbble"></i></a>
                                    </li> */}
                                            <li className="list-inline-item">
                                                <a className="linkedin" href="https://www.linkedin.com/in/shean-m-6a74a227/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                                    <img src="assets/img/mohand.png" alt="team" width="95" className="img-fluid m-auto pb-4" />
                                    <div className="team-content">
                                        <h5 className="mb-0">Mohand Benrezkallah</h5>
                                        <span>{t`Founder`}</span>
                                        {/* <p className="mt-3">Monotonectally engage sticky collaborative markets synergistically</p> */}
                                        <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                            {/* <li className="list-inline-item">
                                        <a className="facebook" href="/#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="twitter" href="/#" target="_blank"><i className="fab fa-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="dribbble" href="/#" target="_blank"><i className="fab fa-dribbble"></i></a>
                                    </li> */}
                                            <li className="list-inline-item">
                                                <a className="linkedin" href="https://www.linkedin.com/in/mohand-benrezkallah-962313123" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                                    <img src="assets/img/uno.jpeg" alt="team" width="120" className="img-fluid m-auto pb-4" />
                                    <div className="team-content">
                                        <h5 className="mb-0">Uno Rodriguez</h5>
                                        <span>{t`Sales Manager`}</span>
                                        {/* <p className="mt-3">Assertively procrastinate standardized whereas technically sound</p> */}
                                        <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                            {/* <li className="list-inline-item">
                                        <a className="facebook" href="/#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="twitter" href="/#" target="_blank"><i className="fab fa-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="dribbble" href="/#" target="_blank"><i className="fab fa-dribbble"></i></a>
                                    </li> */}
                                            <li className="list-inline-item">
                                                <a className="linkedin" href="https://www.linkedin.com/in/uno-rodriguez-a1501b21/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default TeamMember;
