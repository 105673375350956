const hostname = window.location.hostname
const contactFormEndpoint = (hostname === "www.consolarium.com")
  ? "https://www.consolarium.com/contact/"
  : `http://${hostname}:8000/contact/`;

module.exports = {
  contactFormEndpoint,
  contact: {
    title: "Don't hesitate to contact us",
    description:
      "Give us a call or email us anytime, we endeavour to answer all enquiries within 24 hours on business days.",
    addressTitle: "Localisation",
    addressIcon: "fas fa-location-arrow",
    address: "100 Yellow House, Mn Factory, United State, 13420",
    emailTitle: "Email Address",
    emailIcon: "fas fa-envelope",
    email: "sales@consolarium.com",
    phoneTitle: "Phone",
    phoneIcon: "fas fa-phone",
    phone: "+32 472 57 27 13"
  }
};
